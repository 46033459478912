import React from "react";
import { graphql } from 'gatsby'

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import FilterGroup from '../components/Filter/FilterGroup'
import BackToTop from '../components/BackToTop/BackToTop'

const ProductsPage = ({ data, location }) => {

	const categoryTags = data.strapi.categoryTags
	const manufacturers = data.strapi.manufacturers
	const allStrapiTags = data.strapi.tags

	let filter
	let filterTag

	/**
	 * from the location finds out if it needs to be filtered by tag or manufacturer
	 */
	if ( location.search ) {
		let searchQuery = location.search.replace(/[=?]/g, " ")
		//sorting out the search query
		searchQuery = searchQuery.split(" ").splice(1)

		switch ( searchQuery[0] ) {
			case 'tag':
				if ( parseInt( searchQuery[1] ) ) {
					const tagFound = allStrapiTags.find( tag => parseInt( tag.id ) === parseInt( searchQuery[1] ))

					if ( tagFound ) {
						filter = searchQuery[0]
						filterTag = tagFound
					}
				}
				break;
			case 'manufacturer':
				if ( parseInt( searchQuery[1] )) {
					const manufacturerFound = manufacturers.find( manufacturer => parseInt( manufacturer.id ) === parseInt( searchQuery[1] ))

					if ( manufacturerFound ) {
						filter = searchQuery[0]
						filterTag = manufacturerFound
					}
				}
				break;
			case 'search':
				filter = searchQuery[0]
				filterTag = searchQuery[1].replace(/%20/g, " ")
				break;
			default:
				break;
		}
	}

	return (
		<>
			<SEO
				title="Products"
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />

			<FilterGroup
				categoryIds={ [] }
				categoryTags={ categoryTags }
				manufacturers={ manufacturers }
				allStrapiTags={ allStrapiTags }
				preFilterType={ filter }
				preFilterTag={ filterTag } />

			<BackToTop />
		</>
	)
}

export default ProductsPage;

export const query = graphql`
	query allProductsPage {
		strapi {
			categoryTags( where: { allProducts: true } ) {
				label
				tags {
					id
					name
				}
			}
			manufacturers {
				id
				name
			}
			tags {
				id
				name
				tags {
					id
					name
				}
			}
		}
	}
`